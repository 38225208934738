<app-header></app-header>
<div role="main" class="main">
    <div class="slider-container rev_slider_wrapper" style="height: 650px;">
        <div id="revolutionSlider" class="slider rev_slider manual" data-version="5.4.8">
            <ul>
                <li data-transition="fade" data-title="Advocate Team" data-thumb="assets/img/demos/law-firm/slides/slide-law-firm-1.jpg">

                    <img src="assets/img/demos/law-firm/slides/slide-law-firm-1.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg">

                    <div class="tp-caption top-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="-95" data-start="1000" style="z-index: 5" data-transform_in="y:[-300%];opacity:0;s:500;">YOUR TRUSTED</div>

                    <div class="tp-caption main-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="-45" data-start="1500" data-whitespace="nowrap" data-transform_in="y:[100%];s:500;" data-transform_out="opacity:0;s:500;" style="z-index: 5" data-mask_in="x:0px;y:0px;">ADVOCATE TEAM</div>

                    <div class="tp-caption bottom-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="5" data-start="2000" style="z-index: 5" data-transform_in="y:[100%];opacity:0;s:500;">Consult With Our Experienced Team for Solutions.
                    </div>

                    <a class="tp-caption btn btn-primary btn-lg" data-hash data-hash-offset="85" href="#home-intro" data-x="right" data-hoffset="100" data-y="center" data-voffset="80" data-start="2500" data-whitespace="nowrap" data-transform_in="y:[100%];s:500;" data-transform_out="opacity:0;s:500;"
                        style="z-index: 5" data-mask_in="x:0px;y:0px;">Request a
                            Free Consultation</a>

                </li>
                <li data-transition="fade" data-title="Practice Areas" data-thumb="assets/img/demos/law-firm/slides/slide-law-firm-2-thumb.jpg">

                    <img src="assets/img/demos/law-firm/slides/slide-law-firm-2.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg">

                    <div class="tp-caption main-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="-205" data-start="1000" style="z-index: 5; font-size: 40px;" data-transform_in="y:[-300%];opacity:0;s:500;">AREAS</div>

                    <div class="tp-caption tp-caption-overlay-opacity bottom-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="-145" data-start="1500" data-transform_in="x:[-100%];opacity:0;s:500;"><i class="fas fa-check"></i> LLum</div>

                    <div class="tp-caption tp-caption-overlay-opacity bottom-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="-100" data-start="1800" data-transform_in="x:[-100%];opacity:0;s:500;"><i class="fas fa-check"></i> Aigua</div>

                    <div class="tp-caption tp-caption-overlay-opacity bottom-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="-55" data-start="2100" data-transform_in="x:[-100%];opacity:0;s:500;"><i class="fas fa-check"></i> Gas</div>

                    <div class="tp-caption tp-caption-overlay-opacity bottom-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="-10" data-start="2400" data-transform_in="x:[-100%];opacity:0;s:500;"><i class="fas fa-check"></i> Calefacció</div>

                    <div class="tp-caption tp-caption-overlay-opacity bottom-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="35" data-start="2700" data-transform_in="x:[-100%];opacity:0;s:500;"><i class="fas fa-check"></i> Climatització</div>

                    <div class="tp-caption tp-caption-overlay-opacity bottom-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="80" data-start="3000" data-transform_in="x:[-100%];opacity:0;s:500;"><i class="fas fa-check"></i> Domotica</div>

                    <div class="tp-caption tp-caption-overlay-opacity bottom-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="100" data-start="3300" data-transform_in="x:[-100%];opacity:0;s:500;"><i class="fas fa-check"></i> Energies Alternatives</div>

                    <div class="tp-caption tp-caption-overlay-opacity bottom-label" data-x="right" data-hoffset="100" data-y="center" data-voffset="145" data-start="3600" data-transform_in="x:[-100%];opacity:0;s:500;"><i class="fas fa-check"></i> Telecomunicaciosns</div>

                    <a class="tp-caption btn btn-primary btn-lg" data-hash data-hash-offset="85" href="#practice-areas" data-x="right" data-hoffset="205" data-y="center" data-voffset="150" data-start="3700" data-whitespace="nowrap" data-transform_in="y:[100%];s:500;" data-transform_out="opacity:0;s:500;"
                        style="z-index: 5" data-mask_in="x:0px;y:0px;">Learn More
                            <i class="fas fa-long-arrow-alt-right"></i></a>

                </li>
                <li data-transition="fade" data-title="Welcome to Porto" data-thumb="assets/img/demos/law-firm/slides/slide-law-firm-3.jpg">

                    <img src="assets/img/demos/law-firm/slides/slide-law-firm-3.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg">

                    <div class="tp-caption top-label" data-x="left" data-hoffset="100" data-y="center" data-voffset="-95" data-start="1000" style="z-index: 5" data-transform_in="y:[-300%];opacity:0;s:500;">WELCOME TO</div>

                    <div class="tp-caption main-label" data-x="left" data-hoffset="100" data-y="center" data-voffset="-45" data-start="1500" data-whitespace="nowrap" data-transform_in="y:[100%];s:500;" data-transform_out="opacity:0;s:500;" style="z-index: 5" data-mask_in="x:0px;y:0px;">PORTO TEMPLATE</div>

                    <div class="tp-caption bottom-label" data-x="left" data-hoffset="100" data-y="center" data-voffset="5" data-start="2000" style="z-index: 5" data-transform_in="y:[100%];opacity:0;s:500;">The Best HTML Site Template on ThemeForest</div>

                    <a class="tp-caption btn btn-primary btn-lg" href="http://themeforest.net/item/porto-responsive-html5-template/4106987" data-x="left" data-hoffset="100" data-y="center" data-voffset="80" data-start="2500" data-whitespace="nowrap" data-transform_in="y:[100%];s:500;"
                        data-transform_out="opacity:0;s:500;" style="z-index: 5" data-mask_in="x:0px;y:0px;">Purchase Now</a>

                </li>
            </ul>
        </div>
    </div>
    <section class="section section-default section-no-border mt-0">
        <div class="container pt-3 pb-4">
            <div class="row justify-content-around">
                <div class="col-lg-7 mb-4 mb-lg-0">
                    <h2 class="mb-0">Qui som</h2>
                    <div class="divider divider-primary divider-small mb-4">
                        <hr class="mr-auto">
                    </div>
                    <p class="mt-4">Servei Oficial Castell-llum a Calafell, desde 1982. Llum, Aigua, Gas, Calefacció, Climatitzacio, Domotica, Energies Alernatives y Telecomunicacions. ...</p>

                    <a class="mt-3" href="demo-law-firm-about-us.html">Veure mes <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
                <div class="col-lg-4">
                    <h4 class="mb-0">Our Commitment</h4>
                    <div class="divider divider-primary divider-small mb-4">
                        <hr class="mr-auto">
                    </div>
                    <p class="mt-4 mb-0">Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan
                        ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat.</p>
                </div>
            </div>
        </div>
    </section>
    <div class="container" id="practice-areas">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="mt-4 mb-0">Areas</h2>
                <div class="divider divider-primary divider-small divider-small-center mb-4">
                    <hr>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-lg-4">
                <div class="feature-box feature-box-style-2 mb-4 appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="0">
                    <div class="feature-box-icon w-auto mt-3">
                        <img src="assets/img/demos/law-firm/icons/criminal-law.png" alt="" />
                    </div>
                    <div class="feature-box-info ml-3">
                        <h4 class="mb-2">Llum</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet.</p>
                        <a class="mt-3" href="#">Veure mes <i class="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="feature-box feature-box-style-2 mb-4 appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="0">
                    <div class="feature-box-icon w-auto mt-3">
                        <img src="assets/img/demos/law-firm/icons/business-law.png" alt="" />
                    </div>
                    <div class="feature-box-info ml-3">
                        <h4 class="mb-2">{{ 'demo.title' | translate }}Aigua</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
                        <a class="mt-3" href="#">Veure mes <i class="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="feature-box feature-box-style-2 mb-4 appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="0">
                    <div class="feature-box-icon w-auto mt-3">
                        <img src="assets/img/demos/law-firm/icons/health-law.png" alt="" />
                    </div>
                    <div class="feature-box-info ml-3">
                        <h4 class="mb-2">Gas</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
                        <a class="mt-3" href="#">Veure mes <i class="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-lg-3 mb-4">
            <div class="col-lg-4">
                <div class="feature-box feature-box-style-2 mb-2 appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="300">
                    <div class="feature-box-icon w-auto mt-3">
                        <img src="assets/img/demos/law-firm/icons/divorce-law.png" alt="" />
                    </div>
                    <div class="feature-box-info ml-3">
                        <h4 class="mb-2">Calefacció</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet.</p>
                        <a class="mt-3" href="#">Veure mes <i class="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="feature-box feature-box-style-2 mb-2 appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="300">
                    <div class="feature-box-icon w-auto mt-3">
                        <img src="assets/img/demos/law-firm/icons/capital-law.png" alt="" />
                    </div>
                    <div class="feature-box-info ml-3">
                        <h4 class="mb-2">Climatizació</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
                        <a class="mt-3" href="#">Veure mes <i class="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="feature-box feature-box-style-2 mb-2 appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="300">
                    <div class="feature-box-icon w-auto mt-3">
                        <img src="assets/img/demos/law-firm/icons/accident-law.png" alt="" />
                    </div>
                    <div class="feature-box-info ml-3">
                        <h4 class="mb-2">Domótica</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
                        <a class="mt-3" href="#">Veure mes <i class="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-lg-3 mb-4">
            <div class="col-lg-4">
                <div class="feature-box feature-box-style-2 mb-2 appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="300">
                    <div class="feature-box-icon w-auto mt-3">
                        <img src="assets/img/demos/law-firm/icons/divorce-law.png" alt="" />
                    </div>
                    <div class="feature-box-info ml-3">
                        <h4 class="mb-2">Energies Alternatives</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet.</p>
                        <a class="mt-3" href="#">Veure mes <i class="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="feature-box feature-box-style-2 mb-2 appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="300">
                    <div class="feature-box-icon w-auto mt-3">
                        <img src="assets/img/demos/law-firm/icons/capital-law.png" alt="" />
                    </div>
                    <div class="feature-box-info ml-3">
                        <h4 class="mb-2">Telecomunicacions</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque rutrum pellentesque imperdiet. Nulla lacinia iaculis nulla.</p>
                        <a class="mt-3" href="#">Veure mes <i class="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 p-0">
                <section class="section section-primary match-height border-0" style="background-image: url(assets/img/patterns/fancy.jpg);">
                    <div class="row justify-content-end ml-lg-5">
                        <div class="col-half-section col-half-section-right">
                            <h2 class="mb-0">Testimonials</h2>
                            <div class="divider divider-light divider-small mb-4">
                                <hr class="mr-auto">
                            </div>

                            <div class="owl-carousel owl-theme mb-0" data-plugin-options="{'items': 1, 'margin': 10, 'loop': false, 'nav': false, 'dots': true}">
                                <div>
                                    <div class="testimonial testimonial-style-3 testimonial-trasnparent-background testimonial-alternarive-font">
                                        <blockquote class="text-light">
                                            <p class="text-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu pulvinar magna. Phasellus semper scelerisque purus, et semper nisl lacinia sit amet. Praesent venenatis turpis vitae purus semper, eget sagittis
                                                velit venenatis.</p>
                                        </blockquote>
                                        <div class="testimonial-author">
                                            <div class="testimonial-author-thumbnail">
                                                <img src="assets/img/clients/client-1.jpg" class="img-fluid rounded-circle" alt="">
                                            </div>
                                            <p><strong>John Smith</strong><span class="text-light">CEO & Founder - Okler</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="testimonial testimonial-style-3 testimonial-trasnparent-background testimonial-alternarive-font">
                                        <blockquote class="text-light">
                                            <p class="text-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu pulvinar magna. Phasellus semper scelerisque purus, et semper nisl lacinia sit amet.</p>
                                        </blockquote>
                                        <div class="testimonial-author">
                                            <div class="testimonial-author-thumbnail">
                                                <img src="assets/img/clients/client-2.jpg" class="img-fluid rounded-circle" alt="">
                                            </div>
                                            <p><strong>Jessica Smith</strong><span class="text-light">Marketing - Okler</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="testimonial testimonial-style-3 testimonial-trasnparent-background testimonial-alternarive-font">
                                        <blockquote class="text-light">
                                            <p class="text-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu pulvinar magna. Phasellus semper scelerisque purus, et semper nisl lacinia sit amet. Praesent venenatis turpis vitae purus semper, eget sagittis
                                                velit venenatis.</p>
                                        </blockquote>
                                        <div class="testimonial-author">
                                            <div class="testimonial-author-thumbnail">
                                                <img src="assets/img/clients/client-3.jpg" class="img-fluid rounded-circle" alt="">
                                            </div>
                                            <p><strong>Bob Smith</strong><span class="text-light">COO - Okler</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
            <div class="col-lg-6 p-0 visible-md visible-lg">
                <section class="parallax section section-parallax match-height" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}" data-image-src="img/demos/law-firm/parallax/parallax-law-firm.jpg" style="min-height: 450px;">
                </section>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2 class="mt-4 mb-0">Attorneys</h2>
                    <div class="divider divider-primary divider-small divider-small-center mb-4">
                        <hr>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="owl-carousel owl-theme owl-team-custom show-nav-title mb-0" data-plugin-options="{'items': 4, 'margin': 10, 'loop': false, 'nav': true, 'dots': false}">
                    <div class="text-center mb-4">
                        <a href="demo-law-firm-attorneys-detail.html">
                            <img src="assets/img/team/team-22.jpg" class="img-fluid" alt="">
                        </a>
                        <h4 class="mt-3 mb-0">David Doe</h4>
                        <p class="mb-0">Criminal Law
                            <span class="thumb-info-social-icons mt-2 pb-0"></span>
                            <a href="http://www.facebook.com" target="_blank"><i class="fab fa-facebook-f"></i><span>Facebook</span></a>
                            <a href="http://www.twitter.com"><i class="fab fa-twitter"></i><span>Twitter</span></a>
                            <a href="http://www.linkedin.com"><i class="fab fa-linkedin-in"></i><span>Linkedin</span></a>
                        </p>
                    </div>
                    <div class="text-center mb-4">
                        <a href="demo-law-firm-attorneys-detail.html">
                            <img src="assets/img/team/team-23.jpg" class="img-fluid" alt="">
                        </a>
                        <h4 class="mt-3 mb-0">Jeff Doe</h4>
                        <p class="mb-0">Business Law
                            <span class="thumb-info-social-icons mt-2 pb-0"></span>
                            <a href="mailto:mail@example.com"><i class="far fa-envelope"></i><span>Email</span></a>
                            <a href="http://www.linkedin.com"><i class="fab fa-linkedin-in"></i><span>Linkedin</span></a>
                        </p>
                    </div>
                    <div class="text-center mb-4">
                        <a href="demo-law-firm-attorneys-detail.html">
                            <img src="assets/img/team/team-24.jpg" class="img-fluid" alt="">
                        </a>
                        <h4 class="mt-3 mb-0">Craig Doe</h4>
                        <p class="mb-0">Divorce Law
                            <span class="thumb-info-social-icons mt-2 pb-0"></span>
                            <a href="http://www.facebook.com" target="_blank"><i class="fab fa-facebook-f"></i><span>Facebook</span></a>
                            <a href="http://www.twitter.com"><i class="fab fa-twitter"></i><span>Twitter</span></a>
                            <a href="http://www.linkedin.com"><i class="fab fa-linkedin-in"></i><span>Linkedin</span></a>
                        </p>
                    </div>
                    <div class="text-center mb-4">
                        <a href="demo-law-firm-attorneys-detail.html">
                            <img src="assets/img/team/team-25.jpg" class="img-fluid" alt="">
                        </a>
                        <h4 class="mt-3 mb-0">Richard Doe</h4>
                        <p class="mb-0">Accident Law
                            <span class="thumb-info-social-icons mt-2 pb-0"></span>
                            <a href="http://www.linkedin.com"><i class="fab fa-linkedin-in"></i><span>Linkedin</span></a>
                        </p>
                    </div>
                    <div class="text-center mb-4">
                        <a href="demo-law-firm-attorneys-detail.html">
                            <img src="assets/img/team/team-29.jpg" class="img-fluid" alt="">
                        </a>
                        <h4 class="mt-3 mb-0">Amanda Doe</h4>
                        <p class="mb-0">Health Law
                            <span class="thumb-info-social-icons mt-2 pb-0"></span>
                            <a href="http://www.linkedin.com"><i class="fab fa-linkedin-in"></i><span>Linkedin</span></a>
                        </p>
                    </div>
                    <div class="text-center mb-4">
                        <a href="demo-law-firm-attorneys-detail.html">
                            <img src="assets/img/team/team-30.jpg" class="img-fluid" alt="">
                        </a>
                        <h4 class="mt-3 mb-0">Jessica Doe</h4>
                        <p class="mb-0">Capital Law
                            <span class="thumb-info-social-icons mt-2 pb-0"></span>
                            <a href="http://www.linkedin.com"><i class="fab fa-linkedin-in"></i><span>Linkedin</span></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>



    </div>

</div>