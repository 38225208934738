<footer id="footer" class="border-top-0">
    <div class="container py-4">
        <div class="row py-5">
            <div class="col-md-6 mb-4 mb-lg-0">
                <a href="index.html" class="logo pr-0 pr-lg-3 mb-3">
                    <img alt="Castell Llum" src="img/demos/law-firm/logo-law-firm-footer.png" class="opacity-7 top-auto bottom-10" height="33">
                </a>
                <p class="mt-2 mb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu pulvinar magna. Phasellus semper scelerisque purus, et semper nisl lacinia sit amet. Praesent venenatis turpis vitae purus semper...</p>
                <p class="mb-0"><a href="#" class="btn-flat btn-xs text-color-light"><strong class="text-2">Veure mes</strong><i class="fas fa-angle-right p-relative top-1 pl-2"></i></a></p>
            </div>
            <div class="col-md-3 mb-4 mb-lg-0">
                <h5 class="text-3 mb-3">CONTACTANS</h5>
                <ul class="list list-icons list-icons-lg">
                    <li class="mb-1"><i class="far fa-dot-circle text-color-primary"></i>
                        <p class="m-0">Manuel Soler, 20, Calafell (Tarragona)</p>
                    </li>
                    <li class="mb-1"><i class="fab fa-whatsapp text-color-primary"></i>
                        <p class="m-0"><a href="tel:977693188">(800) 693188</a></p>
                    </li>
                    <li class="mb-1"><i class="far fa-envelope text-color-primary"></i>
                        <p class="m-0"><a href="mailto:info@castell-llum.com">info@castell-llum.com</a></p>
                    </li>
                </ul>
            </div>
            <div class="col-md-3">
                <h5 class="text-3 mb-3">SEGUEIX-NOS </h5>
                <ul class="header-social-icons social-icons">
                    <li class="social-icons-linkedin"><a href="http://www.linkedin.com/" target="_blank" title="Linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="footer-copyright footer-copyright-style-2">
        <div class="container py-2">
            <div class="row py-4">
                <div class="col d-flex align-items-center justify-content-center">
                    <p>© Copyright 2020. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer>