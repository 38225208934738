import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagenofound',
  templateUrl: './pagenofound.component.html',
  styleUrls: ['./pagenofound.component.scss']
})
export class PageNofoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
