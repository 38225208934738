<app-analytics>
</app-analytics>

<div class="body">
    <app-header></app-header>
    <button (click)="useLanguage('es')">es</button>
    <button (click)="useLanguage('ca')">ca</button>

    <app-menu></app-menu>
    <router-outlet></router-outlet>


    <app-footer></app-footer>
</div>