import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'castellllum';
  params: Params;

  constructor(private route: ActivatedRoute, private translate: TranslateService){
    translate.setDefaultLang('es');
  
  }
  useLanguage(language){
    this.translate.use(language);
  }
  ngOnInit() {
    
  this.route.queryParams.subscribe((params: Params) => {
    this.params = params;
    //console.log('id', params);
    console.log('App params', params);
    const id = params['id'];
    console.log('id', id);
    
  });
  
  
};
}
