import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-serveis',
  templateUrl: './serveis.component.html',
  styleUrls: ['./serveis.component.scss']
})
export class ServeisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
