import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LanguageComponent } from './components/language/language.component';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { ServeisComponent } from './components/serveis/serveis.component';
import { PageNofoundComponent } from './components/pagenofound/pagenofound.component';



const routes: Routes = [


  { path: ':lang', 
    component: HomeComponent, children: [
    //all your toutes here
      {
        path: 'contacte',
        component: ContactComponent,
      },
      {
        path: 'serveis',
        component: ServeisComponent,
        children: [
          { path: ':id', component: ContactComponent, },
        ]
      }
    ] 
  },
  
  {
    path: 'es/404',
    component: PageNofoundComponent,
  },
  { 
    path: 'es/**',
    redirectTo:'404',
  },
  { 
    path: 'ca/**',
    redirectTo:'404',
  }
  
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
