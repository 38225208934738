<header id="header" data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyStartAt': 98, 'stickySetTop': '-98px', 'stickyChangeLogo': true}">
    <div class="header-body border-color-primary border-top-0 box-shadow-none">
        <div class="header-container container z-index-2">
            <div class="header-row">
                <div class="header-column">
                    <div class="header-row">
                        <div class="header-logo">
                            <a href="demo-law-firm.html">
                                <img alt="Porto" width="164" height="54" data-sticky-width="112" data-sticky-height="37" data-sticky-top="86" src="assets/img/demos/law-firm/logo-law-firm.png">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="header-column justify-content-end">
                    <div class="header-row h-100">
                        <ul class="header-extra-info d-flex h-100 align-items-center">
                            <li class="align-items-center h-100 py-4 header-border-right pr-4 d-none d-md-inline-flex">
                                <div class="header-extra-info-text h-100 py-2">
                                    <div class="feature-box feature-box-style-2 align-items-center">
                                        <div class="feature-box-icon">
                                            <i class="far fa-envelope text-7 p-relative"></i>
                                        </div>
                                        <div class="feature-box-info pl-1">
                                            <label>Envianos un correo</label>
                                            <strong><a href="mailto:info@castell-llum.com">info@Castell-llum.com</a></strong>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="align-items-center h-100 py-4">
                                <div class="header-extra-info-text h-100 py-2">
                                    <div class="feature-box feature-box-style-2 align-items-center">
                                        <div class="feature-box-icon">
                                            <i class="fab fa-whatsapp text-7 p-relative"></i>
                                        </div>
                                        <div class="feature-box-info pl-1">
                                            <label>Llámanos</label>
                                            <strong><a href="tel:977693188">977 693 188</a></strong>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-nav-bar bg-primary" data-sticky-header-style="{'minResolution': 991}" data-sticky-header-style-active="{'background-color': 'transparent'}" data-sticky-header-style-deactive="{'background-color': '#0088cc'}">
            <div class="container">
                <div class="header-row">
                    <div class="header-column">
                        <div class="header-row">
                            <div class="header-column">
                                <div class="header-nav header-nav-stripe header-nav-divisor header-nav-force-light-text justify-content-start" data-sticky-header-style="{'minResolution': 991}" data-sticky-header-style-active="{'margin-left': '150px'}" data-sticky-header-style-deactive="{'margin-left': '0'}">
                                    <div class="header-nav-main header-nav-main-square header-nav-main-effect-1 header-nav-main-sub-effect-1">
                                        <nav class="collapse">
                                            <ul class="nav nav-pills" id="mainNav">
                                                <li class="dropdown dropdown-full-color dropdown-light">
                                                    <a class="dropdown-item active" href="#">
                                                            Castell - Llum    <h1>{{ 'demo.title' | translate }}</h1>

                                                    </a>
                                                </li>
                                                <li class="dropdown dropdown-full-color dropdown-light">
                                                    <a class="dropdown-item" href="demo-law-firm-about-us.html">
                                                            Serveis
                                                    </a>
                                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <a class="dropdown-item" href="#" routerLink="./es/serveis" routerLinkActive="active">Tots els serveis</a>
                                                        <div class="dropdown-divider"></div>
                                                        <a class="dropdown-item" href="#" routerLink="./serveis/?id=1" routerLinkActive="active">Servei 1</a>
                                                    </div>
                                                </li>
                                                <li class="dropdown dropdown-full-color dropdown-light">
                                                    <a class="dropdown-item" routerLink="['./contacte', lang]" href="#" routerLinkActive="active">Contacte
                                                        
                                                    </a>
                                                </li>

                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div class="header-column">
                                <div class="header-row justify-content-end">
                                    <div class="header-nav-features header-nav-features-no-border w-75">
                                        <form role="search" class="d-flex w-100">
                                            <div class="simple-search input-group w-100">
                                                <input class="form-control border-0 border-radius-0 text-2" id="headerSearch" name="q" type="search" value="" placeholder="Buscar...">
                                                <span class="input-group-append bg-light border-0 border-radius-0">
                                                    <button class="btn" type="submit">
                                                        <i class="fa fa-search header-nav-top-icon"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <button class="btn header-btn-collapse-nav" data-toggle="collapse" data-target=".header-nav-main nav">
                                <i class="fas fa-bars"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<nav class="collapse">
    <ul class="nav nav-pills" id="mainNav">
        <li class="dropdown dropdown-full-color dropdown-light">
            <a class="nav-link" href="#">Castell - Llum <span class="sr-only">(current)</span></a>
        </li>

        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Serveis
          </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#" routerLink="./es/serveis" routerLinkActive="active">Tots els serveis</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" routerLink="es/serveis/?id=1" routerLinkActive="active">Servei 1</a>
            </div>
        </li>
        <li class="nav-item" routerLink="./es/contacte" routerLinkActive="active">
            <a class="nav-link" href="#" routerLink="./es/contacte" routerLinkActive="active">Contacte</a>
        </li>

    </ul>
    <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Buscar</button>
    </form>


</nav>